import React from "react";
import LoadingSpinner from "./loadingSpinner";

export interface ISmallCardProps {
    title?: string;
    content?: string;
    hasBorder?: boolean;
    isLoading?: boolean;
}

const SmallCardComponent: React.FC<ISmallCardProps> = ({ title, content, hasBorder, isLoading }) => {
    let className = "flex flex-column w-full md:w-18rem md:max-w-18rem bg-orange-50 border-round-lg";

    if (hasBorder) {
        className += " border-2 border-orange-200";
    }

    return (
        <div className={className}>
            {title && <label className="text-sm p-1 pb-0 border-bottom-1 border-orange-200">{title}</label>}
            <div className="flex py-2 justify-content-center align-items-center h-full">
                <LoadingSpinner isLoading={isLoading ?? false} size="small"></LoadingSpinner>
                {!isLoading && <label className="text-lg font-semibold">{content}</label>}
            </div>
        </div>
    );
};

export default SmallCardComponent;
