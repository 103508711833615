import MainHeaderComponent from "./mainHeader";
import SideNavbarComponent from "./sideNavbar";
import { Outlet } from "react-router-dom";
import HomeComponent from "../home/home";
import { useAuth } from "../../hooks/useAuth";
import { GlobalTeamStateContextProvider } from "../../contexts/globalTeamState/globalTeamStateContext";

const DefaultLayoutComponent: React.FC = () => {
    const { isAuthenticated } = useAuth();

    return (
        <div className="flex flex-auto flex-column justify-content-start gap-0 h-100 min-h-screen surface-50">
            <MainHeaderComponent></MainHeaderComponent>
            <div className="flex flex-auto gap-1 h-100 flex-column lg:flex-row">
                {isAuthenticated && <SideNavbarComponent></SideNavbarComponent>}
                {isAuthenticated ? (
                    <div className="pt-3 pb-3 pr-5 pl-5 flex flex-column w-full">
                        <GlobalTeamStateContextProvider>
                            <Outlet></Outlet>
                        </GlobalTeamStateContextProvider>
                    </div>
                ) : (
                    <HomeComponent></HomeComponent>
                )}
            </div>
        </div>
    );
};

export default DefaultLayoutComponent;
