import { IPlanFeature } from "../../models/plans/planFeature";
import { Skeleton } from "primereact/skeleton";

export interface IPlanFeaturesProps {
    features: IPlanFeature[];
    planName?: string;
    isLoading?: boolean;
}

const PlanFeaturesComponent: React.FC<IPlanFeaturesProps> = ({ features, planName, isLoading }) => {
    const getFeatureIconOrNumber = (feature: IPlanFeature) => {
        if (feature.available && feature.amount === 0) {
            return <i className="pi pi-check mr-3" data-testid="plan-feature-check-icon"></i>;
        } else if (feature.available && feature.amount > 0) {
            return (
                <label className="inline-block mr-3 w-1rem text-center font-semibold" data-testid="plan-feature-amount-label">
                    {feature.amount}
                </label>
            );
        } else {
            return <i className="pi pi-times mr-3" data-testid="plan-feature-times-icon"></i>;
        }
    };

    const getFeatureLineElement = (feature: IPlanFeature, index: number) => {
        return (
            <li key={`${planName ?? ""}-feature-${index}`} className="mb-2">
                <span>
                    {getFeatureIconOrNumber(feature)}
                    {feature.description}
                </span>
            </li>
        );
    };

    if (isLoading === true) {
        return (
            <ul className="p-0 list-none mb-6">
                <li>
                    <Skeleton width="20rem" className="mb-2"></Skeleton>
                </li>
                <li>
                    <Skeleton width="20rem" className="mb-2"></Skeleton>
                </li>
                <li>
                    <Skeleton width="20rem" className="mb-2"></Skeleton>
                </li>
            </ul>
        );
    }

    return <ul className="p-0 list-none mb-6">{features.map(getFeatureLineElement)}</ul>;
};

export default PlanFeaturesComponent;
