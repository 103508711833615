import PageSectionComponent from "../common/pageSection";
import PlanInformationComponent from "./planInformation";
import { IPlanInformation } from "../../models/plans/planInformation";
import { useGetAvailablePlansInformation } from "../../api/apiClient";
import { useAuth } from "../../hooks/useAuth";
import LoadingSpinner from "../common/loadingSpinner";

export interface IAvailablePlansComponentProps {
    activePlanName?: string;
}

const AvailablePlansComponent: React.FC<IAvailablePlansComponentProps> = ({ activePlanName }) => {
    const { accessToken, userInformation } = useAuth();
    const { data: availablePlans, isFetching } = useGetAvailablePlansInformation(userInformation?.workspaceId ?? "", accessToken);

    return (
        <PageSectionComponent className="h-full">
            <div className="flex flex-row align-items-stretch gap-5 h-full">
                <LoadingSpinner isLoading={isFetching}></LoadingSpinner>
                {!isFetching &&
                    availablePlans?.map((plan: IPlanInformation) => (
                        <PlanInformationComponent
                            key={plan.name}
                            name={plan.name}
                            price={plan.price}
                            features={plan.features}
                            theme={plan.price === 0 ? "lighter" : "light"}
                            isActive={activePlanName !== undefined && plan.name === activePlanName}
                        ></PlanInformationComponent>
                    ))}
            </div>
        </PageSectionComponent>
    );
};

export default AvailablePlansComponent;
