import { useContext } from "react";
import { GlobalTeamStateContext, IGlobalTeamStateContextValues } from "../contexts/globalTeamState/globalTeamStateContext";

export const useGlobalTeamState = (): IGlobalTeamStateContextValues => {
    const context = useContext(GlobalTeamStateContext);

    if (!context) {
        throw new Error("useGlobalTeamState must be used within a GlobalTeamStateContextProvider");
    }

    return context;
};
