import React from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../common/loadingSpinner";
import { useGetAvailableChannels, useCreateScheduledQuestion, useGetSchedulesForWorkspace } from "../../api/apiClient";
import { useAuth } from "../../hooks/useAuth";
import AddScheduledQuestionForm from "./addScheduledQuestionForm";
import { ICreateRecurringQuestionRequest } from "../../dtos/createRecurringQuestionRequest";
import { useUserFeedback } from "../../hooks/useUserFeedback";
import ScheduledQuestionsList from "./scheduledQuestionsList";
import { useQueryClient } from "react-query";
import { useDeleteScheduledQuestion } from "../../api/schedule/scheduleApiClient";
import { Message } from "primereact/message";
import { useGlobalTeamState } from "../../hooks/useGlobalTeamState";

const AutomationDashboardComponent: React.FC = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { accessToken, userInformation } = useAuth();
    const { showSuccessMessage, showErrorMessage } = useUserFeedback();
    const { teamState } = useGlobalTeamState();
    const { data: availableChannels, isFetching: getAvailableChannelsFetching } = useGetAvailableChannels(
        userInformation?.workspaceId ?? "",
        accessToken
    );
    const { data: existingSchedules, isFetching: getSchedulesIsFething } = useGetSchedulesForWorkspace(
        userInformation?.workspaceId ?? "",
        accessToken
    );
    const { mutateAsync: saveScheduledQuestion, isLoading: saveScheduledQuestionIsLoading } = useCreateScheduledQuestion(
        userInformation?.workspaceId ?? "",
        accessToken,
        queryClient
    );
    const { mutateAsync: deleteScheduledQuestion, isLoading: deleteScheduledQuestionIsLoading } = useDeleteScheduledQuestion(
        userInformation?.workspaceId ?? "",
        accessToken,
        queryClient
    );

    const isLoading = getAvailableChannelsFetching || getSchedulesIsFething || saveScheduledQuestionIsLoading || deleteScheduledQuestionIsLoading;

    const saveRecurringQuestion = (request: ICreateRecurringQuestionRequest) => {
        saveScheduledQuestion(request)
            .then(() => {
                showSuccessMessage(t("scheduledQuestionSaved"));
            })
            .catch(({ response }) => {
                showErrorMessage(response.data);
            });
    };

    const deleteRecurringQuestion = (scheduleId: string) => {
        deleteScheduledQuestion(scheduleId)
            .then(() => {
                showSuccessMessage(t("scheduledQuestionDeleted"));
            })
            .catch(({ response }) => {
                showErrorMessage(response.data);
            });
    };

    const isAddDisabled = existingSchedules !== undefined && existingSchedules.length >= teamState.allowedSchedules;

    return (
        <>
            <h1 className="font-bold mt-0 pt-0">{t("automate")}</h1>
            <LoadingSpinner isLoading={isLoading}></LoadingSpinner>
            {(!availableChannels || availableChannels.length === 0) && !isLoading && (
                <Message className="justify-content-start" severity="info" text={t("addToChannelToSchedule")} />
            )}
            {!isLoading && availableChannels && availableChannels.length > 0 && (
                <div className="flex flex-column gap-3">
                    {teamState && teamState.allowedSchedules !== undefined && teamState.allowedSchedules > 0 && (
                        <Message
                            className="justify-content-start"
                            severity="info"
                            text={t("limitedScheduledQuestions", { allowedSchedules: teamState.allowedSchedules })}
                        />
                    )}
                    {teamState && teamState.allowedSchedules !== undefined && teamState.allowedSchedules === 0 && (
                        <Message className="justify-content-start" severity="warn" text={t("noScheduledQuestionsAllowed")} />
                    )}
                    <AddScheduledQuestionForm
                        avaialbleChannels={availableChannels}
                        disabled={isAddDisabled}
                        onSubmit={saveRecurringQuestion}
                    ></AddScheduledQuestionForm>
                    <ScheduledQuestionsList schedules={existingSchedules || []} onScheduleDelete={deleteRecurringQuestion}></ScheduledQuestionsList>
                </div>
            )}
        </>
    );
};

export default AutomationDashboardComponent;
