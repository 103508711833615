import React, { PropsWithChildren } from "react";

export interface SmallCardProps {
    title?: string;
    className?: string;
}

const PageSectionComponent: React.FC<PropsWithChildren<SmallCardProps>> = ({ title, className: customClassName, children }) => {
    let className = "flex flex-column gap-3 p-4 border-round border-2 border-orange-200";

    if (title) {
        className += " pt-1";
    }

    if (customClassName) {
        className += ` ${customClassName}`;
    }

    return (
        <section className={className}>
            {title && (
                <label>
                    <small><b>{title}</b></small>
                </label>
            )}
            {children}
        </section>
    );
};

export default PageSectionComponent;
