import React, { createContext } from "react";

export interface IEnvironmentContextValues {
    slackSignInURL: string | undefined;
    paypalClientId: string;
    paypalIsSandboxEnvironment: boolean;
    isDevelopment: boolean;
    standardPlanId: string;
}

export const EnvironmentContext = createContext<IEnvironmentContextValues | null>(null);

export function EnvironmentContextProvider(props: { value?: IEnvironmentContextValues; children?: React.ReactNode }) {
    const slackSignInURL = process.env.REACT_APP_SLACK_SIGN_IN_URL ?? "";
    const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "";
    const paypalIsSandboxEnvironment = process.env.REACT_APP_PAYPAL_IS_SANDBOX === "1";
    const isDevelopment = process.env.NODE_ENV === "development";
    const standardPlanId = process.env.REACT_APP_STANDARD_PLAN_ID ?? "";

    return (
        <EnvironmentContext.Provider
            value={props.value ?? { slackSignInURL, paypalClientId, paypalIsSandboxEnvironment, isDevelopment, standardPlanId }}
        >
            {props.children}
        </EnvironmentContext.Provider>
    );
}
