import { Dialog } from "primereact/dialog";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { createSubscriptionWithPaypal } from "../../api/apiClient";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useUserFeedback } from "../../hooks/useUserFeedback";
import { logError, logInfo, startNewSpan } from "../../utils/telemetryUtils";

export interface IPlanCheckoutModalComponentProps {
    setVisible: (value: boolean) => void;
}

const PlanCheckoutModalComponent: React.FC<IPlanCheckoutModalComponentProps> = ({ setVisible }) => {
    const { t } = useTranslation();
    const { accessToken, userInformation } = useAuth();
    const { isDevelopment, standardPlanId } = useEnvironment();
    const { showErrorMessage } = useUserFeedback();
    const navigate = useNavigate();

    return (
        <Dialog header={t("checkout")} visible={true} style={{ minWidth: "25vw", maxWidth: "40vw" }} onHide={() => setVisible(false)}>
            <div className="flex flex-column gap-4">
                <label className="text-center">{t("paypalPayments")}</label>
                <small className="text-center">{t("cancelReminder")}</small>
                {!isDevelopment && (
                    <PayPalButtons
                        style={{
                            shape: "pill",
                            layout: "vertical",
                            disableMaxWidth: true,
                        }}
                        createSubscription={async () => {
                            const span = startNewSpan("create-subscription-start");

                            try {
                                const data = await createSubscriptionWithPaypal(userInformation?.workspaceId ?? "", standardPlanId, accessToken);

                                if (data.isSuccess) {
                                    logInfo(span, "create-subscription-success", {
                                        userId: userInformation?.id,
                                        workspaceId: userInformation?.workspaceId,
                                        subscriptionId: data.subscriptionId,
                                    });

                                    return data.subscriptionId;
                                } else {
                                    data.problemDetails.forEach((problemDetail) => {
                                        showErrorMessage(
                                            problemDetail.message,
                                            `Error code: ${problemDetail.debug_id} - ${problemDetail.description}`
                                        );
                                    });
                                }
                            } catch (error) {
                                logError(span, "create-subscription-error", {
                                    userId: userInformation?.id,
                                    workspaceId: userInformation?.workspaceId,
                                    message: error as string,
                                });
                                showErrorMessage(t("failedToCallApiToCreateSubscription"));
                            }

                            span.end();
                            return "";
                        }}
                        onApprove={async (data, actions) => {
                            const span = startNewSpan("pay-subscription-success");

                            if (data.orderID && data.subscriptionID) {
                                logInfo(span, "completed-payment", {
                                    userId: userInformation?.id,
                                    workspaceId: userInformation?.workspaceId,
                                    orderId: data.orderID,
                                    subscriptionId: data.subscriptionID,
                                });
                                navigate(`/plans/confirmation/${data.subscriptionID}`);
                            }

                            span.end();
                        }}
                        onCancel={(data) => {
                            const span = startNewSpan("pay-subscription-cancel");
                            logInfo(span, "canceled-payment", {
                                userId: userInformation?.id,
                                workspaceId: userInformation?.workspaceId,
                            });

                            span.end();
                        }}
                    />
                )}
            </div>
        </Dialog>
    );
};

export default PlanCheckoutModalComponent;
