export interface ITeamLevelsDetailProps {
    levelName: string;
    levelDescription: string;
    backgroundColor: string;
}

const TeamLevelsDetail: React.FC<ITeamLevelsDetailProps> = ({levelName, levelDescription, backgroundColor}) => {
    return (
        <div className={`w-full h-6rem p-1 pl-4 flex flex-row gap-4 ${backgroundColor}`}>
            <div className="w-2 flex flex-column justify-content-center text-xl"><label>{levelName}</label></div>
            <div className="w-10 flex flex-column justify-content-center">{levelDescription}</div>
        </div>
    );
};

export default TeamLevelsDetail;
