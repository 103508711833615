import React, { createContext } from "react";
import { ITeamStateDto } from "../../models/team/teamState";
import { useGetTeamState } from "../../api/apiClient";
import { useAuth } from "../../hooks/useAuth";

const defaultTeamState: ITeamStateDto = {
    id: "",
    hasCustomName: false,
    name: "",
    isFreePlan: true,
    planName: "",
    allowedSchedules: 0,
    permissions: [],
};

export interface IGlobalTeamStateContextValues {
    teamState: ITeamStateDto;
    isLoading: boolean;
    canAccess: (permission: string) => boolean;
}

export const GlobalTeamStateContext = createContext<IGlobalTeamStateContextValues | null>(null);

export function GlobalTeamStateContextProvider(props: { value?: IGlobalTeamStateContextValues; children?: React.ReactNode }) {
    const { accessToken, userInformation } = useAuth();
    const { data: teamState, isFetching } = useGetTeamState(userInformation?.workspaceId ?? "", accessToken);

    const canAccess = (permission: string): boolean => {
        return teamState?.permissions.includes(permission) ?? false;
    };

    return (
        <GlobalTeamStateContext.Provider
            value={
                props.value ?? {
                    teamState: teamState ?? defaultTeamState,
                    isLoading: isFetching,
                    canAccess,
                }
            }
        >
            {props.children}
        </GlobalTeamStateContext.Provider>
    );
}
