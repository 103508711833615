import { useTranslation } from "react-i18next";
import TeamLevelsDetail from "./teamLevelDetail";
import PageSectionComponent from "../common/pageSection";

const TeamLevelsDetailList: React.FC = () => {
    const { t } = useTranslation();

    return (
        <PageSectionComponent className="mt-2">
            <h3 className="m-0">{t("levelDetails")}</h3>
            <div className="w-full h-full flex flex-column">
                <TeamLevelsDetail levelName={t("teamLevel1")} levelDescription={t("teamLevel1Description")} backgroundColor="bg-primary-50"/>
                <TeamLevelsDetail levelName={t("teamLevel2")} levelDescription={t("teamLevel2Description")} backgroundColor="bg-primary-100"/>
                <TeamLevelsDetail levelName={t("teamLevel3")} levelDescription={t("teamLevel3Description")} backgroundColor="bg-primary-200"/>
                <TeamLevelsDetail levelName={t("teamLevel4")} levelDescription={t("teamLevel4Description")} backgroundColor="bg-primary-300"/>
            </div>
        </PageSectionComponent>
    );
};

export default TeamLevelsDetailList;
