import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

export interface ILoadingSpinnerProps {
    isLoading: boolean;
    size?: "small" | "auto";
}

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({ isLoading, size = "auto" }) => {
    const customStyle = size === "small" ? { width: "2rem", height: "2rem" } : {};

    return (
        <>
            {isLoading && (
                <div className="h-full w-full flex align-items-center" data-testid="loading-spinner">
                    <ProgressSpinner style={customStyle} />
                </div>
            )}
        </>
    );
};

export default LoadingSpinner;
