import React from "react";
import { useTranslation } from "react-i18next";
import QuickStatisticsComponent from "./quickStatistics";

const DashboardComponent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-column gap-4">
            <QuickStatisticsComponent></QuickStatisticsComponent>
            {t("welcomeToQOTD")}
        </div>
    );
};

export default DashboardComponent;
