import { QueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { IPlan } from "../../models/plans/plan";
import { ICreateSubscriptionResponse } from "../../dtos/createSubscriptionResponse";
import { IPlanInformation } from "../../models/plans/planInformation";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/dashboard/subscription`;

export const useGetPlanInformation = (workspaceId: string, token?: string) => {
    const apiPath = `${basePath}/${workspaceId}`;
    const queryKey = `getSubscriptionInformation-${token}`;

    return useQuery<IPlan>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<IPlan>(apiPath, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((res: any) => {
                        return res.data;
                    });
            }

            return Promise.resolve(null);
        },
        {
            refetchOnWindowFocus: false,
            cacheTime: 0,
        }
    );
};

export const useGetAvailablePlansInformation = (workspaceId: string, token?: string) => {
    const apiPath = `${basePath}/${workspaceId}/availablePlans`;
    const queryKey = `useGetAvailableSubscriptionTypesInformation-${token}`;

    return useQuery<IPlanInformation[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<IPlanInformation[]>(apiPath, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve(null);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useGetSubscriptionTypeInformation = (workspaceId: string, planId: string, token: string | undefined, queryClient: QueryClient) => {
    const apiPath = `${basePath}/${workspaceId}/planInformation/${planId}`;
    const queryKey = `useGetSubscriptionTypeInformation-${token}`;

    return useQuery<IPlanInformation>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<IPlanInformation>(apiPath, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((res: any) => {
                        queryClient.invalidateQueries(`getTeamState-${token}`);
                        return res.data;
                    });
            }

            return Promise.resolve(null);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useCancelPlan = (workspaceId: string, token: string | undefined, queryClient: QueryClient) => {
    const apiPath = `${basePath}/${workspaceId}/cancel`;

    return useMutation({
        mutationFn: () => {
            if (token) {
                return axios
                    .delete<IPlan>(apiPath, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve(null);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`getSubscriptionInformation-${token}`);
        },
    });
};

export const createSubscriptionWithPaypal = async (workspaceId: string, planId: string, token?: string): Promise<ICreateSubscriptionResponse> => {
    const apiPath = `${basePath}/${workspaceId}/paypal/${planId}`;

    return axios
        .post<ICreateSubscriptionResponse>(apiPath, undefined, {
            headers: {
                Authorization: token,
            },
        })
        .then((res: any) => res.data);
};
