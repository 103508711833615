import { IPlanFeature } from "../../models/plans/planFeature";
import logo from "../../assets/QOTD-new-logo.svg";
import { useTranslation } from "react-i18next";
import PlanFeaturesComponent from "./planFeatures";

export interface IPlanInformationProps {
    name: string;
    price: number;
    features: IPlanFeature[];
    theme: "light" | "lighter";
    isActive: boolean;
}

const PlanInformationComponent: React.FC<IPlanInformationProps> = ({ name, price, features, theme, isActive }) => {
    const { t } = useTranslation();
    const isFree = price === 0;
    let containerClassName = "flex flex-column flex-grow-1 align-items-center p-4 border-3 border-round-lg";

    if (theme === "lighter") {
        containerClassName += ` bg-orange-50 ${isActive ? 'border-black' : 'border-orange-200'}`;
    } else if (theme === "light") {
        containerClassName += ` bg-orange-100 ${isActive ? 'border-black' : 'border-orange-300'}`;
    }

    return (
        <div className={containerClassName}>
            <img src={logo} alt={t("qotdLogoAltText")} className="h-auto w-8rem" />
            <h3>{name}</h3>
            <PlanFeaturesComponent planName={name} features={features}></PlanFeaturesComponent>
            <div className="flex flex-row align-items-center gap-2 font-semibold">
                <label className="text-xl">$</label>
                <label className="text-6xl">{price}</label>
            </div>
            {isFree ? null : <small className="mt-2">{t("chargedMonthly")}</small>}
        </div>
    );
};

export default PlanInformationComponent;
