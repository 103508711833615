import React, { createContext, useRef } from "react";
import { Toast } from "primereact/toast";

export interface IUserFeedbackContextValues {
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string, summary?: string) => void;
}

export const UserFeedbackContext = createContext<IUserFeedbackContextValues | null>(null);

export function UserFeedbackContextProvider(props: { value?: IUserFeedbackContextValues; children?: React.ReactNode }) {
    const toast = useRef<Toast>(null);

    const showSuccessMessage = (message: string) => {
        toast.current?.show({ severity: "success", summary: "Success", detail: message });
    };

    const showErrorMessage = (message: string, summary: string = "Oops") => {
        toast.current?.show({ severity: "error", summary, detail: message, sticky: true });
    };

    return (
        <UserFeedbackContext.Provider value={props.value ?? { showSuccessMessage, showErrorMessage }}>
            <Toast ref={toast} />
            {props.children}
        </UserFeedbackContext.Provider>
    );
}
