import { HoneycombWebSDK } from "@honeycombio/opentelemetry-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";

const configDefaults = {
    ignoreNetworkEvents: true,
    // propagateTraceHeaderCorsUrls: [
    // /.+/g, // Regex to match your backend URLs. Update to the domains you wish to include.
    // ]
};

const sdk = new HoneycombWebSDK({
    debug: false, // Set to false for production environment.
    apiKey: process.env.REACT_APP_TELEMETRY_API_KEY,
    serviceName: process.env.REACT_APP_TELEMETRY_DATA_SET,
    instrumentations: [
        getWebAutoInstrumentations({
            "@opentelemetry/instrumentation-xml-http-request": configDefaults,
            "@opentelemetry/instrumentation-fetch": configDefaults,
            "@opentelemetry/instrumentation-document-load": configDefaults,
        }),
    ],
});

sdk.start();
console.log("OpenTelemetry initialized");
