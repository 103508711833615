import "./App.css";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-orange/theme.css";
import { PrimeReactProvider } from "primereact/api";
import DefaultLayoutComponent from "./components/layout/defaultLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignInComponent from "./components/auth/signIn";
import SignOutComponent from "./components/auth/signOut";
import TeamSettingsComponent from "./components/settings/teamSettings";
import { UserFeedbackContextProvider } from "./contexts/userFeedback/userFeedbackContext";
import DashboardComponent from "./components/dashboard/dashboard";
import InstallComponent from "./components/auth/install";
import AutomationDashboardComponent from "./components/automation/automationDashboard";
import PostInstallComponent from "./components/auth/postInstall";
import { useEffect } from "react";
import PlanDashboardComponent from "./components/plans/planDashboard";
import { PayPalScriptProvider, ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import { useEnvironment } from "./hooks/useEnvironment";
import PlanConfirmationComponent from "./components/plans/planConfirmation";

const App = () => {
    useEffect(() => {
        document.title = "QOTD - Dashboard";
    });
    const queryClient = new QueryClient();
    const { paypalClientId, paypalIsSandboxEnvironment } = useEnvironment();

    const router = createBrowserRouter([
        {
            path: "/",
            element: <DefaultLayoutComponent />,
            children: [
                { path: "/team", element: <TeamSettingsComponent /> },
                { path: "/automate", element: <AutomationDashboardComponent /> },
                { path: "/plans/confirmation/:subscriptionId", element: <PlanConfirmationComponent /> },
                { path: "/plans", element: <PlanDashboardComponent /> },
                { path: "/", element: <DashboardComponent /> },
            ],
        },
        {
            path: "/signin",
            element: <SignInComponent />,
        },
        {
            path: "/post-install",
            element: <PostInstallComponent />,
        },
        {
            path: "/sign-out",
            element: <SignOutComponent />,
        },
        { path: "/install", element: <InstallComponent /> },
    ]);

    const initialOptions: ReactPayPalScriptOptions = {
        clientId: paypalClientId,
        enableFunding: "",
        disableFunding: "paylater,card",
        dataSdkIntegrationSource: "integrationbuilder_sc",
        vault: "true",
        environment: paypalIsSandboxEnvironment ? "sandbox" : "production",
        intent: "subscription",
    };

    return (
        <QueryClientProvider client={queryClient}>
            <PrimeReactProvider>
                <UserFeedbackContextProvider>
                    <PayPalScriptProvider options={initialOptions}>
                        <RouterProvider router={router} />
                    </PayPalScriptProvider>
                </UserFeedbackContextProvider>
            </PrimeReactProvider>
        </QueryClientProvider>
    );
};

export default App;
