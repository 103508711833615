import React from "react";
import { useTranslation } from "react-i18next";
import SmallCardComponent from "../common/smallCard";
import { useGlobalTeamState } from "../../hooks/useGlobalTeamState";

const QuickStatisticsComponent: React.FC = () => {
    const { t } = useTranslation();
    const { teamState } = useGlobalTeamState();

    return (
        <div className="flex flex-row h-8rem gap-4 py-3 px-3 bg-orange-200 border-round-lg">
            {teamState.hasCustomName && <SmallCardComponent title={t("teamName")} content={teamState.name}></SmallCardComponent>}
            <SmallCardComponent title={t("yourPlan")} content={teamState.planName}></SmallCardComponent>
        </div>
    );
};

export default QuickStatisticsComponent;
