import { useTranslation } from "react-i18next";
import logo from "../../assets/QOTD-new-logo.svg";
import PageSectionComponent from "../common/pageSection";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useGetSubscriptionTypeInformation } from "../../api/apiClient";
import PlanFeaturesComponent from "./planFeatures";
import { useQueryClient } from "react-query";

const PlanConfirmationComponent: React.FC = () => {
    const { t } = useTranslation();
    const { accessToken, userInformation } = useAuth();
    const { subscriptionId } = useParams<{ subscriptionId: string }>();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {
        data: subscriptionType,
        isFetching,
        isError,
    } = useGetSubscriptionTypeInformation(userInformation?.workspaceId ?? "", subscriptionId ?? "", accessToken, queryClient);

    if (isError) {
        navigate("/");
    }

    return (
        <PageSectionComponent className="align-items-center justify-content-center w-full h-full">
            <img src={logo} alt={t("qotdLogoAltText")} className="h-auto w-10rem" />
            <h3>{t("thankYouForSubscribing")}</h3>
            <label>
                {t("successfullyPurchased")}
                <b>{subscriptionId}</b>
            </label>
            <label>
                {t("enjoyTheBenefits")}
                <b>{t("standardPlan")}</b>:
            </label>
            <PlanFeaturesComponent
                features={subscriptionType?.features ?? []}
                planName={subscriptionType?.name}
                isLoading={isFetching}
            ></PlanFeaturesComponent>
            <small>{t("cancelReminder")}</small>
            <small>
                {t("supportContact")} <a href="mailto:teamqotd@gmail.com">{t("here")}</a>
            </small>
        </PageSectionComponent>
    );
};

export default PlanConfirmationComponent;
