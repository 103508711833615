import { useTranslation } from "react-i18next";
import { IPlan } from "../../models/plans/plan";
import SmallCardComponent from "../common/smallCard";
import PageSectionComponent from "../common/pageSection";
import { Button } from "primereact/button";

export interface CurrentPlanInformationComponentProps {
    plan: IPlan | undefined;
    isLoading?: boolean;
    onCheckoutStart: () => void;
    onCancelPlan: () => Promise<void>;
}

const CurrentPlanInformationComponent: React.FC<CurrentPlanInformationComponentProps> = ({ plan, isLoading, onCheckoutStart, onCancelPlan }) => {
    const { t } = useTranslation();
    const isFreePlan = plan ? plan.price === 0 : true;
    const userCanCancelPlan: boolean = plan !== undefined && plan !== null && plan.price !== 0 && plan.isOwner && !plan.isCanceled;

    const getPriceContent = (price: number | undefined): string => {
        if (price === undefined || price === 0) {
            return t("free");
        }

        return `USD ${price}`;
    };

    const getExpirationDate = (plan: IPlan | undefined): string => {
        if (plan === undefined || plan === null || plan.price === 0) {
            return t("never");
        }

        return new Date(plan.expirationDate).toLocaleDateString(undefined, {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        });
    };

    return (
        <PageSectionComponent title={t("currentPlan")}>
            <div className="flex flex-column md:flex-row gap-3 md:gap-4 md:h-5rem">
                <SmallCardComponent title={t("name")} content={plan?.name ?? ""} isLoading={isLoading} hasBorder={true}></SmallCardComponent>
                <SmallCardComponent
                    title={t("price")}
                    content={getPriceContent(plan?.price)}
                    isLoading={isLoading}
                    hasBorder={true}
                ></SmallCardComponent>
                <SmallCardComponent
                    title={t("expiresOn")}
                    content={getExpirationDate(plan)}
                    isLoading={isLoading}
                    hasBorder={true}
                ></SmallCardComponent>
                {!isLoading && isFreePlan && (
                    <Button
                        label={t("switchToPremium")}
                        size="large"
                        className="md:align-self-center md:ml-auto w-full md:w-2 p-button-rounded"
                        onClick={onCheckoutStart}
                    ></Button>
                )}
                {!isLoading && !isFreePlan && !plan?.isCanceled && (
                    <Button
                        label={t("cancelPremium")}
                        severity="danger"
                        size="large"
                        className="md:align-self-center md:ml-auto w-full md:w-2 p-button-rounded"
                        onClick={onCancelPlan}
                        disabled={!userCanCancelPlan}
                    ></Button>
                )}
                {!isLoading && !isFreePlan && plan?.isCanceled && (
                    <Button
                        label={t("premiumCanceled")}
                        severity="secondary"
                        size="large"
                        className="md:align-self-center md:ml-auto w-full md:w-2 p-button-rounded"
                        disabled={true}
                    ></Button>
                )}
            </div>
            {!isLoading && !isFreePlan && plan?.isCanceled && <small id="premium-canceled-help">{t("premiumCanceledTooltip")}</small>}
        </PageSectionComponent>
    );
};

export default CurrentPlanInformationComponent;
