import { useTranslation } from "react-i18next";
import CurrentPlanInformationComponent from "./currentPlanInformation";
import PlanCheckoutModalComponent from "./planCheckoutModal";
import { useState } from "react";
import { useCancelPlan, useGetPlanInformation } from "../../api/apiClient";
import { useAuth } from "../../hooks/useAuth";
import { useQueryClient } from "react-query";
import { useUserFeedback } from "../../hooks/useUserFeedback";
import AvailablePlansComponent from "./availablePlans";

const PlanDashboardComponent: React.FC = () => {
    const { t } = useTranslation();
    const [isCheckoutModalVisible, setIsCheckoutModalVisible] = useState(false);
    const { accessToken, userInformation } = useAuth();
    const queryClient = useQueryClient();
    const { data: planInformation, isFetching: isPlanInformationFetching } = useGetPlanInformation(userInformation?.workspaceId ?? "", accessToken);
    const { mutateAsync: cancelPlan } = useCancelPlan(userInformation?.workspaceId ?? "", accessToken, queryClient);
    const { showSuccessMessage, showErrorMessage } = useUserFeedback();

    const showCheckoutModal = () => {
        setIsCheckoutModalVisible(true);
    };

    const cancelCurrentPlan = async () => {
        cancelPlan()
            .then(() => {
                showSuccessMessage(t("planCanceled"));
            })
            .catch(() => {
                showErrorMessage(t("errorOccurred"));
            });
    };

    return (
        <div className="flex flex-column gap-3">
            <h1 className="font-bold mt-0 pt-0">{t("teamPlan")}</h1>
            <CurrentPlanInformationComponent
                plan={planInformation}
                isLoading={isPlanInformationFetching}
                onCheckoutStart={showCheckoutModal}
                onCancelPlan={cancelCurrentPlan}
            ></CurrentPlanInformationComponent>
            <AvailablePlansComponent activePlanName={planInformation?.name}></AvailablePlansComponent>
            {isCheckoutModalVisible && <PlanCheckoutModalComponent setVisible={setIsCheckoutModalVisible}></PlanCheckoutModalComponent>}
        </div>
    );
};

export default PlanDashboardComponent;
