import React, { useState } from "react";
import { ITeam } from "../../models/team/team";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useGlobalTeamState } from "../../hooks/useGlobalTeamState";
import { SEASONAL_QUESTIONS_PERMISSION, SELECT_TEAM_LEVEL_PERMISSION } from "../../constants/businessLogicConstants";
import PageSectionComponent from "../common/pageSection";

interface TeamSettingsFormProps {
    team: ITeam;
    onSave: (updatedTeam: ITeam) => void;
}

interface ITeamSettingsFormValidationState {
    isFormValid: boolean;
    isTeamNameValid: boolean;
    isTeamLevelValid: boolean;
}

const TeamSettingsForm: React.FC<TeamSettingsFormProps> = ({ team, onSave }) => {
    const { t } = useTranslation();
    const { canAccess } = useGlobalTeamState();
    const [teamName, setTeamName] = useState<string>(team.name);
    const [teamLevel, setTeamLevel] = useState<string>(team.level);
    const [seasonalQuestionsEnabled, setSeasonalQuestionsEnabled] = useState<boolean>(team.seasonal_enabled);
    const [validationState, setValidationState] = useState<ITeamSettingsFormValidationState>({
        isFormValid: true,
        isTeamNameValid: true,
        isTeamLevelValid: true,
    });

    const availableLevels = [
        { name: t("teamLevel1"), level: "1" },
        { name: t("teamLevel2"), level: "2" },
        { name: t("teamLevel3"), level: "3" },
        { name: t("teamLevel4"), level: "4" },
    ];

    const gatherFormInformationAndSave = () => {
        if (teamName.length > 0 && teamLevel !== null && teamLevel !== undefined) {
            onSave({
                ...team,
                name: teamName,
                level: teamLevel,
                seasonal_enabled: seasonalQuestionsEnabled,
            });
        }
    };

    const updateTeamNameValidity = (newTeamName: string) => {
        setValidationState((prevState) => {
            prevState.isTeamNameValid = newTeamName.length > 0;
            prevState.isFormValid = prevState.isTeamNameValid && prevState.isTeamLevelValid;

            return { ...prevState };
        });
    };

    const updateTeamNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateTeamNameValidity(e.target.value);
        setTeamName(e.target.value);
    };

    const updateTeamLevelValue = (e: DropdownChangeEvent) => {
        setTeamLevel(e.value);
    };

    return (
        <PageSectionComponent>
            <div className="pt-2 pb-1 flex md:flex-row flex-column gap-6">
                <div className="flex flex-column gap-2 md:w-20rem w-full">
                    <label htmlFor="teamName">{t("teamName")}</label>
                    <InputText
                        id="teamName"
                        value={teamName}
                        className="p-inputtext-lg"
                        aria-describedby="teamname-help"
                        role="textbox"
                        invalid={!validationState.isTeamNameValid}
                        maxLength={25}
                        onChange={updateTeamNameValue}
                    />
                    {validationState.isTeamNameValid ? (
                        <small id="teamname-help">{t("teamNameHelp")}</small>
                    ) : (
                        <small id="teamname-help" className="text-red-500">
                            {t("teamNameRequired")}
                        </small>
                    )}
                </div>
                {canAccess(SELECT_TEAM_LEVEL_PERMISSION) && (
                    <div className="flex flex-column gap-2 md:w-20rem w-full">
                        <label htmlFor="teamLevel">{t("teamLevel")}</label>
                        <Dropdown
                            id="teamLevel"
                            options={availableLevels}
                            optionLabel="name"
                            optionValue="level"
                            value={teamLevel}
                            className="p-inputtext-lg"
                            aria-describedby="teamlevel-help"
                            role="combobox"
                            invalid={!validationState.isTeamLevelValid}
                            onChange={updateTeamLevelValue}
                        />
                        {validationState.isTeamLevelValid ? (
                            <small id="teamlevel-help">{t("teamLevelHelp")}</small>
                        ) : (
                            <small id="teamlevel-help" className="text-red-500">
                                {t("teamLevelRequired")}
                            </small>
                        )}
                    </div>
                )}
                {canAccess(SEASONAL_QUESTIONS_PERMISSION) && (
                    <div className="flex flex-column gap-2 md:w-20rem w-full">
                        <label htmlFor="seasonalQuestionsToggle">{t("seasonalQuestionsEnabled")}</label>
                        <InputSwitch
                            className="mt-2 mb-3"
                            inputId="seasonalQuestionsToggle"
                            checked={seasonalQuestionsEnabled}
                            onChange={(e) => setSeasonalQuestionsEnabled(e.value)}
                        />
                        <small id="seasonalQuestions-help">{t("seasonalQuestionsHelp")}</small>
                    </div>
                )}
            </div>
            <Button
                label={t("save")}
                size="large"
                className="md:max-w-10rem w-full"
                severity="success"
                disabled={!validationState.isFormValid}
                onClick={gatherFormInformationAndSave}
            ></Button>
        </PageSectionComponent>
    );
};

export default TeamSettingsForm;
