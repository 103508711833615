import { Span, trace } from "@opentelemetry/api";

export const startNewSpan = (name: string): Span => {
    const tracer = trace.getTracer("default");

    return tracer.startSpan(name);
};

export const logInfo = (span: Span, eventName: string, eventParams?: any): void => {
    span.addEvent(eventName, { ...eventParams, severity: "info" });
};

export const logError = (span: Span, eventName: string, eventParams?: any): void => {
    span.addEvent(eventName, { ...eventParams, severity: "error" });
};
